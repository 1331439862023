
import Vue from 'vue'
export default Vue.extend({
	
	data() {
		return {
			valid: true,
			itsm: '',
			itsmRules: [ (v:string) => !!v || 'Please Select ITSM' ],
			nbLicences: 0,
			nbLicencesRules: [
				(v: number) => !!v || 'number of licences needed is required',
				(v:number) => v > 0 || 'more than 0 please'
			],
			tenantId: '',
			tenantIdRules: [
				(v:string) => !!v || 'Please fill the client TenantId'
			],
			genesysDeploymentId: '',
			genesysDeploymentIdRules: [
				(v:string) => !!v || 'Please fill the genesys deployment Id'
			],

		}
	},

	methods: {
		async save() {
            this.$store.commit('projectForm/setNbLicences', this.nbLicences)
            this.$store.commit('projectForm/setItsm', this.itsm)
			this.$store.commit('projectForm/setTenantId', this.tenantId)
			this.$store.commit('projectForm/setGenesysDeploymentId', this.genesysDeploymentId)

			await this.$store.dispatch('projectForm/save')
		},

		async deployDone() {
			if(this.$refs.form && (this.$refs.form as Vue & { validate: () => boolean }).validate()) {
				this.$store.commit('projectForm/setNbLicences', this.nbLicences)
				this.$store.commit('projectForm/setItsm', this.itsm)
				this.$store.commit('projectForm/setTenantId', this.tenantId)
				this.$store.commit('projectForm/setGenesysDeploymentId', this.genesysDeploymentId)

				this.$store.commit('projectForm/setDeploymentStepDone', true)

				await this.$store.dispatch('projectForm/save')

				await this.$store.dispatch('mail/send', {
					to: this.$store.getters['projectForm/users'],
					subject: 'Deployment step is done for project ' + this.$store.getters['projectForm/clientName'],
					html: '<p>Hello</p><p>Deployment step is done for client ' + this.$store.getters['projectForm/clientName'] + '</p><a href="https://teams4it-follow.wats-apps.com/project/' + this.$store.getters['projectForm/id'] + '">Check here</a>'
				})
			}
		}
	},

	mounted() {
		this.itsm = this.$store.getters['projectForm/itsm']
		this.nbLicences = this.$store.getters['projectForm/nbLicences']
		this.tenantId = this.$store.getters['projectForm/tenantIdClient']
		this.genesysDeploymentId = this.$store.getters['projectForm/genesysDeploymentId']
	}

})
